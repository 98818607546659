<script>
import Api from '@/api/core/deliverManage';
import { cloneDeep, isEmpty, isEqual, pick } from 'lodash-es';
import dayjs from 'dayjs';
import moment from 'moment/moment';

export default {
  name: 'Yesterday',
  data: () => ({
    layout: {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    },
    formData: {
      advertiserName: undefined,
      operatorId: [],
      page: 1,
      size: 100,
      sort: 1,
    },

    loading: false,

    columns: [
      {
        dataIndex: 'objectiveName',
        key: 'objectiveName',
        title: '指标',
        scopedSlots: { customRender: 'name' },
        customCell(record) {
          return {
            class: record.status === 1 ? 'p-table-td__red' : '',
          };
        },
      },
      {
        title: '昨日/目标',
        dataIndex: '',
        scopedSlots: { customRender: 'Objective' },
      },
      {
        title: '近7日均值/目标',
        dataIndex: '',
        key: '',
        scopedSlots: { customRender: 'recentlyObjective' },
      },
      {
        title: '预警值',
        dataIndex: 'warningValue',
        key: 'warningValue',
        scopedSlots: { customRender: 'warning' },
      },
    ],
    data: [],
    total: 0,

    pitcherList: [],

    pagination: {},
  }),
  created() {
    try {
      const params = JSON.parse(sessionStorage.getItem('__Yesterday_params__') || '{}');
      if (!isEmpty(params)) {
        this.formData = { ...this.formData, ...pick(params, ['advertiserName', 'page', 'size', 'sort', 'operatorId']) };
      }
    } finally {
      console.log('finally-err');
    }
    this.$nextTick(this.init);
  },
  methods: {
    async init() {
      await this.getUsersList();
      await this.getList();
    },

    dayjs,
    handleReset() {
      this.formData = cloneDeep(this.$options.data().formData);
      this.getList();
    },
    handleSearch() {
      this.formData.page = 1;
      this.formData.size = 100;
      this.getList();
    },
    // 获取数据
    async getList() {
      this.loading = true;
      const params = cloneDeep(this.formData);
      Api.getYesterdayList({
        ...params,
        operatorId: params.operatorId.join(','),
      })
        .then((res) => {
          sessionStorage.setItem('__Yesterday_params__', JSON.stringify(params));
          if (isEqual(res.code, 200)) {
            this.data = res.data.list || [];
            this.total = res.data.total || 0;
          } else {
            this.data = [];
            this.total = 0;
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取投手
    async getUsersList() {
      this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
        if (res.code === 200) {
          this.pitcherList = res.data.list;
        } else {
          this.$message.error(`获取投手列表失败，${ res.message }`);
        }
      });
    },

    // 处理分页
    handlePaginationChange(current, pageSize) {
      this.formData.page = current;
      this.formData.size = pageSize;
      this.getList();
    },

    // 保存预警值
    putYesterdayWarning(item) {
      const enums = {
        线索单价: 'clueUnitPriceWarning',
        线索量: 'clueWarning',
        消耗: 'costWarning',
        '私信线索转化率（%）': 'msgClueConvertWarning',
        私信开口人数: 'msgOpenNumWarning',
        私信开口单价: 'msgUnitPriceWarning',
        'UBL消耗占比（%）': 'ublCostProportionWarning',
      };

      const data = {
        manageId: item.manageId,
        clueUnitPriceWarning: '',
        clueWarning: '',
        costWarning: '',
        msgClueConvertWarning: '',
        msgOpenNumWarning: '',
        msgUnitPriceWarning: '',
        ublCostProportionWarning: '',
      };

      (item.list || []).forEach((v) => {
        // if(v.type){
        //   data[v.type] = v.warningValue
        // }

        if (v.objectiveName && v.objectiveName in enums) {
          data[enums[v.objectiveName]] = v.warningValue;
        }
      });

      Api.putYesterdayWarningApi(data)
        .then((res) => {
          if (isEqual(res.code, 200)) {
            this.$message.success('保存成功');
            this.handleSearch();
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToPage(advertiserName) {
      this.$router.push({
        path: '/advertisingOptimizationBoard',
        query: {
          advertiserName,
        },
      });
    },
  },
};
</script>

<template>
  <ACard class="p-yesterday">
    <APageHeader title="昨日异常广告主" />
    <a-form-model ref="form" :model="formData" v-bind="layout">
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="广告主">
            <a-input v-model="formData.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"></a-col>
        <a-col :span="7">
          <a-form-model-item label="投手">
            <a-select
              v-model="formData.operatorId"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 100%"
              mode="multiple"
            >
              <a-select-option v-for="item in pitcherList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col class="flex-end">
          <a-space>
            <base-button :title="'重置'" @onClickBtn="handleReset"></base-button>
            <base-button :type="'primary'" :title="'查询'" @onClickBtn="handleSearch"></base-button>
          </a-space>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="排序">
            <base-button
              :type="formData.sort == 1 ? 'primary' : ''"
              title="剩余天数少优先"
              @onClickBtn="
                formData.sort = formData.sort == 0 ? 1 : 0;
                handleSearch();
              "
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <ASpin v-if="loading" class="p-loading" />

    <ARow :gutter="[10, 10]" style="width: 100%" type="flex">
      <ACol :span="8" :lg="8" :xl="8" :xxl="8" v-for="(item, index) of data" :key="item.advertiserId + `---${index}`">
        <ACard class="p-card">
          <ASpace direction="vertical" :size="10">
            <ARow style="">
              <ACol :span="6">目标编码： {{ item.code }}</ACol>
              <ACol :span="8">{{ item.advertiserName }}</ACol>
              <ACol :span="10">
                <span v-if="item.startTime && item.endTime">
                  {{ dayjs(item.startTime).format('YYYY/M/D') }}
                  -
                  {{ dayjs(item.endTime).format('YYYY/M/D') }}
                </span>
                <span v-else>-</span>
              </ACol>
              <ACol :span="24">{{ item.operatorName }}</ACol>
              <ACol :span="24">
                已进行{{ item.passDays || 0 }}天， 剩余{{ item.remainDays || 0 }}天， 累计异常{{
                  item.exceptionDay || 0
                }}天。
              </ACol>
            </ARow>

            <ATable
              class="p-table"
              :columns="columns"
              :data-source="item.list || []"
              bordered
              :scroll="{ x: false, y: 300 }"
              :pagination="false"
            >
              <div slot="name" slot-scope="text" class="p-card__td">{{ text }}</div>
              <div slot="Objective" slot-scope="row">
                {{ row.yesterdayTotal || 0 }} /
                {{ row.yesterdayObjective || 0 }}
              </div>
              <div slot="recentlyObjective" slot-scope="row">
                {{ row.recentlyTotal || 0 }} /
                {{ row.recentlyObjective || 0 }}
              </div>
              <template slot="warning" slot-scope="text, record">
                <ASpace>
                  <span>{{ record.symbol || '-' }}</span>
                  <AInputNumber :min="1" :max="999999" v-model="record.warningValue" style="width: 100%" />
                </ASpace>
              </template>
            </ATable>

            <div class="p-card__footer">
              <BaseButton type="link" title="查看巨量广告优化建议 >" @onClickBtn="goToPage(item.advertiserName)" />
              <BaseButton title="保存预警值" @onClickBtn="putYesterdayWarning(item)" />
            </div>
          </ASpace>
        </ACard>
      </ACol>
    </ARow>

    <div class="p-empty" v-if="!data.length">
      <AEmpty />
    </div>

    <base-pagination
      :currentPage="formData.page"
      :pageSize="formData.size"
      :total="total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </ACard>
</template>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.p-yesterday {
  position: relative;
}

/deep/ .ant-card-body {
  padding: 0 16px 8px;
}

.p-card {
  font-size: 12px;

  /deep/ .ant-space.ant-space-vertical {
    width: 100%;
  }

  /deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: auto;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.p-loading {
  background: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-empty {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-table {
  /deep/ .ant-table-tbody > tr > td.p-table-td__red {
    background: #ff0000;
  }

  /deep/ .ant-table-thead > tr > th,
  /deep/ .ant-table-tbody > tr > td {
    padding: 2px 6px;
    font-size: 12px;
  }
}
</style>
